html {
	box-sizing: border-box;
	font-family: $copy-font-family;
	font-size: 16px;	
}

body {
	margin: 0;
	background-color: $light-gray;
}

hr {
	margin: 3em 0;
	border: 0;
	border-bottom: 5px solid $dark-border-color;
}

.iframe-wrapper {
	position: relative;
    overflow: hidden;
    padding-top: 56.25%;

	iframe {
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    border: 0;
	}
}

.flex-wrapper {
	display: flex;
}

img {
	// background-color: $border-color;
	// min-height: 100px;
	width: 100%;
	pointer-events: none;
}

#ad {
	grid-column: 2/12;
	a {
		display: block;
		&:hover {
			opacity: 0.8;
		}
	}
}

// Forms 

label {
	display: block;
	font-weight: bold;
	margin-bottom: 0.5em;
}

fieldset {
	border: none;
	padding: 0;
	margin-bottom: 2rem;
}

input, textarea {
	font-size: 1.5rem;
	padding: 10px;
	border: 1px solid $border-color;
	resize: none;
	width: 90%;
}

button, .button {
	border: 1px solid $yellow;
	border-radius: 100px;
	color: $base-color;
	font-weight: bold;
	font-size: 1rem;
	padding: 1em;

	&:hover {
		border-color: $red;
	}
}

ul {
	li {
		margin-bottom: 1em;
	}
}



// Extras
.sharer {
	display: flex;
	align-items: center;
	padding: 1em 0;

	a {
		display: inline-block;
		font-size: 2rem;
		margin: 0 10px;
	}
}

.more-loader {
	text-align: center;
	padding: 5em 0;

	@media screen and (max-width: $small-device) {
		padding: 0;
		a {
			display: block;
			margin: 1em;
		}
	}
}

.alert {
	background-color: #83a8bb1f;
	color: $blue;
	text-align: center;
	padding: 1em;
	display: none;
}

body.from-send {
	.alert {
		display: block;
	}
}

