// Grid 

.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

// Page Specific Layout Styles
#home {
    .posts-roll {
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
    }
}


// Helper Layout Classes
.fixed-width-container {
	max-width: 1200px;
	margin: 0 auto;
}