.rankings {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;

  h2 {
    grid-column: 1 / -1;
  }

  h2, h3 {
    margin-bottom: 0;
  }
}
