// Homepage Styles
#home {
    #hero {
        background-color: #fff;
        padding: 3em 0 5em 0;

        h1 {
            margin: 0;
            font-size: 30px;

            &::after {
                content: '';
                display: block;
                height: 1px;
                width: 90px;
                background-color: $border-color;
                margin: 1em 0;
            }
        }

        p {
            max-width: 700px;
            opacity: 0.5;
        }

        @media (max-width: 500px) {
            padding: 1em;
        }
    }

    #posts {
        margin-top: 50px;

        .img-wrapper {
            height: 259px;
            overflow: hidden;

            a {
                display: block;
            }
        }

        .posts-roll {
            h3 {
                margin-bottom: 0.1em;
            }

            p {
                opacity: 0.5;
            }

            .date-author {
                font-size: 14px;
            }
        }

        @media (max-width: 500px) {
            padding: 1em;

            .posts-roll {
                grid-template-columns: repeat(1, 1fr);
            }

            .article {
                border-bottom: 1px solid $border-color;
            }
        }

        @media (min-width: 501px) and (max-width: 800px) {
            padding: 1em;

            .posts-roll {
                grid-template-columns: repeat(2, 1fr);
            }

            .article {
                border-bottom: 1px solid $border-color;
            }
        }
    }
}