body {
	font-size: 17px;
	font-family: $base-font-family;
}

body, caption, th, td, input, textarea, select, option, legend, fieldset, h1, h2, h3, h4, h5, h6 {
	font-size-adjust: 0.5;
}

#page {
	font-size: 1rem;
	/* equivalent to 16px */
	line-height: 1.25;
	/* equivalent to 20px */
}

@media (min-width: 43.75rem) {
	#page {
		font-size: 1rem;
		/* equivalent to 16px */
		line-height: 1.375;
		/* equivalent to 22px */
	}
}

h1 {
	font-size: 2rem;
	/* 2x body copy size = 32px */
	line-height: 1.25;
	/* 45px / 36px */
}

@media (min-width: 43.75rem) {
	h1 {
		font-size: 2.5rem;
		/* 2.5x body copy size = 40px */
		line-height: 1.125;
	}
}

@media (min-width: 56.25rem) {
	h1 {
		font-size: 3rem;
		/* 3x body copy size = 48px */
		line-height: 1.05;
		/* keep to a multiple of the 20px line height and something more appropriate for display headings */
	}
}

h2 {
	font-size: 1.625rem;
	/* 1.625x body copy size = 26px */
	line-height: 1.15384615;
	/* 30px / 26px */
}

@media (min-width: 43.75rem) {
	h2 {
		font-size: 2rem;
		/* 2x body copy size = 32px */
		line-height: 1.25;
	}
}

@media (min-width: 56.25rem) {
	h2 {
		font-size: 2.25rem;
		/* 2.25x body copy size = 36px */
		line-height: 1.25;
	}
}

h3 {
	font-size: 1.375rem;
	/* 1.375x body copy size = 22px */
	line-height: 1.13636364;
	/* 25px / 22px */
}

@media (min-width: 43.75rem) {
	h3 {
		font-size: 1.5rem;
		/* 1.5x body copy size = 24px */
		line-height: 1.25;
	}
}

@media (min-width: 56.25rem) {
	h3 {
		font-size: 1.75rem;
		/* 1.75x body copy size = 28px */
		line-height: 1.25;
	}
}

h4 {
	font-size: 1.125rem;
	/* 1.125x body copy size = 18px */
	line-height: 1.11111111;
}

@media (min-width: 43.75rem) {
	h4 {
		line-height: 1.22222222;
		/* (22px / 18px */
	}
}

blockquote {
	font-size: 1.25rem;
	/* 20px / 16px */
	line-height: 1.25;
	/* 25px / 20px */
}

@media (min-width: 43.75rem) {
	blockquote {
		font-size: 1.5rem;
		/* 24px / 16px = */
		line-height: 1.45833333;
		/* 35px / 24px */
	}
}

/* ------------------------ */

p {
	font-family: $copy-font-family;
	line-height: 1.5rem;

	a {
		font-weight: bold;
		border-bottom: 1px dotted $border-color;
	}
}

a {
	text-decoration: none;
	color: $font-color;
	transition: all 0.4s;

	&:hover {
		color: $hover-color !important;
	}

	&.highlighted {
		color: $hover-color;
	}
}


ul {
	padding-left: 0;
	line-height: 1.5rem;
	
	@media screen and (max-width: 500px) {
		padding-left: 1em;
	}
}

blockquote {
	margin: 3rem 0;
	line-height: 2em;
	color: $yellow;
}

.section-heading {
	@extend h3;
		font-family: $base-font-family;
	display: flex;
	align-items: center;
	justify-content: space-between;
	
	&::after {
	content: '';
	display: inline-block;
	height: 1px;
	width: 80%;
	background-color: $border-color;
	}
}

.subtitle {
	font-weight: 200;
}

.date-author {
	opacity: 0.5;
	font-family: $base-font-family;
}

.subtext {
	font-size: 16px;
	color: #555;
}