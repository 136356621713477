// Site Header Styles

#site-header {
	background: #fff;
	padding: 2em;

	.logo {
		img { 
			width: 200px;
			background-color: transparent;
		}
	}

	.site-nav {
		display: flex;
		justify-content: space-between;

		a {
			padding: 1rem;
			font-weight: bold;
		}
	}

	.site-title {
		flex-basis: auto;
	}

	.social {
		margin-left: 3rem;
	}

	@media screen and (max-width: 500px) {
		> div {
			flex-direction: column;
		}
	}

	> div {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}